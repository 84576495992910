import React from 'react';
import News from './components/News';

function App() {
  return (
    <>
      <News />
    </>
  );
}

export default App;